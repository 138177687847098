import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, useQuery } from "gatsby"
import Img from "gatsby-image"


import HeaderMinimal from "../../components/header/HeaderMinimal"
import MainMenu from "../../components/header/Menu"
import Footer from "../../components/footer/Footer"

import Sidebar from "../../components/major-pieces/sidebar/Sidebar"
import Subheader from "../../components/major-pieces/subheader/Subheader"
import SubMenu from "../../components/major-pieces/subheader/SubMenu"
import PageBlock from "../../components/major-pieces/pageblock/PageBlock"
import ERIPageBlocks from "../../components/major-pieces/pageblock/ERIPageBlocks"
import getPageBlockFromData from "../../components/major-pieces/pageblock/PageBlock"

import "../../styles/global.css"
import "../../styles/global_media.css"
import "../../styles/menu.css"
import "../../styles/clergy.css"
import JSONData from "../../../content/clergy/data.json"


function ERI () {
	
	
	const query = graphql`
	      query {
	        allFile(
	          sort: { fields: name, order: ASC }
	          filter: { relativePath: { glob: "images/pages/clergy/*.*" } }
	        ) {
	          edges {
	            node {
	              id
	              name
	              childImageSharp {
	                fluid {
	                  ...GatsbyImageSharpFluid
	                }
	              }
	            }
	          }
	        }
	    }
	`
	let getMemberBlock = (member, key, imgs) => {
		let img = imgs.edges.find(element => element.node.name === member.photo_name)
		let name = <span dangerouslySetInnerHTML={{__html:member.firstName}}></span>
		let wifeName = <div>{member.wifeName}</div>

		if(member.hasOwnProperty("title") && !!member.title){
			name = <div>{name}{", "+member.title}</div>
		}

		let email = ""
		if(member.hasOwnProperty("email") && !!member.email){
			email = <div><a href="mailto: {member.email}">{member.email}</a></div>
		}
		let link=""
		if(member.hasOwnProperty("link") && !!member.link){
			link = <div><a href='{member.link}'>{member.link_label}</a></div>
		}
		let phone = ""
		if(member.hasOwnProperty("phone") && !!member.phone){
			phone = <div>Phone: {member.phone}</div>
		}
		let image = ""
		if(img != null){
			image = <Img className="clergy-img" fluid={img.node.childImageSharp.fluid} />
		}
		console.log(image)
		return <li key={key} className="member">{image}<div className="info">{name}{wifeName}{email}{phone}{link}</div></li>
	}

	let data = useStaticQuery(query)
	let images = data.allFile
	const members = JSONData.clergy.map(
    	(member, key) => getMemberBlock(member,key,images)
	)



	console.log(data)

	let subheaderData = JSONData.subheader; 

	
	const location = ""
	

	return(
		<div className="site body">
			<HeaderMinimal />
			<MainMenu metadata="sub-page"/>
			<div className="line-across menu-line-across"></div>
			<main className="site-content clergy">
				<div>
					<Subheader data={subheaderData} />
				</div>
				<ul className="members">
					{members}
				</ul>
			</main>
			<Footer />		
		</div>
		)
}

export default ERI 